import React, { useEffect, useState } from "react";
import { usePopupContext } from "../../context/PopupContext";
import { useUserContext } from "../../context/UserContext";
import CheckEmail from "../Forms/CheckEmail/CheckEmail";
import "./PaymentBlock.css";
import AllTariffDetailPopup from "../Forms/TariffDetailPopUp/AllTariffDetailPopUp";
import BasicTariffDetailPopup from "../Forms/TariffDetailPopUp/BasicTariffDetailPopup";
import AnnettTariffDetailPopup from "../Forms/TariffDetailPopUp/AnnettTariffDetailPopup";
import AllTariffPayment from "../Forms/AllProducts/AllTariffPayment";
import AnnettTariffPayment from "../Forms/AllProducts/AnnettTariffPayment";
import BasicTariffPayment from "../Forms/AllProducts/BasicTariffPayment";

function PaymentBlock(props) {
  const { innoRef } = props;
  const [selectedTariff, setSelectedTariff] = useState('');
  const { setActive, setContent, setFromTrialPopup, setType } = usePopupContext();
  const { userProfile, setIsCertificate, products } = useUserContext();


  const startDateValue = '2024-05-01 00:00:00';
  const endDateValue = '2024-05-31 23:59:59';
  const [isShow, setIsShow] = useState(false);

  const getTimeStampWithTimeZone = (timeZone, type, date = '') => {
    const inputDate = (date !== '') ? new Date(date) : new Date();
    const formattedDate = new Intl.DateTimeFormat(type, {timeZone}).format(inputDate);
    return new Date(formattedDate).getTime();
  };
  const nowTime = getTimeStampWithTimeZone('Europe/Moscow', 'en-EN');
  const startTime = getTimeStampWithTimeZone('Europe/Moscow', 'en-EN', startDateValue);
  const endTime = getTimeStampWithTimeZone('Europe/Moscow', 'en-EN', endDateValue);

  useEffect(() => {
    if (nowTime >= startTime && nowTime <= endTime) {
      setIsShow(true);
    }
  }, []);
  
  useEffect(() => {
    if (!userProfile.online) {
      setContent(<CheckEmail selectedTariff={selectedTariff} />);
    }
  }, [selectedTariff, userProfile.online]);

  const showBasicTariffDetail = (e) => {
    dataLayer.push({ event: 'morebaz' });
    e.preventDefault();
    setContent('');
    setActive(true);
    setContent(<BasicTariffDetailPopup />);
  };
  const buyBasicTariff = () => {
    const ids = [products[1]?.[0]?.id, products[2]?.[1]?.id, products[4]?.[0]?.id].filter(id => id !== undefined).join(',');
    if (ids) {
      window.location.href = `/payment/?product_id=${ids}`;
    }
  };

  const showAnnettTariffDetail = (e) => {
    dataLayer.push({ event: 'moreann' });
    e.preventDefault();
    setContent('');
    setActive(true);
    setContent(<AnnettTariffDetailPopup />);
  };
  const buyAnnettTariff = () => {
    const ids = [products[0]?.[1]?.id, products[2]?.[0]?.id, products[3]?.[1]?.id].filter(id => id !== undefined).join(',');
    if (ids) {
      window.location.href = `/payment/?product_id=${ids}`;
    }
  };

  const showAllTariffDetail = (e) => {
    dataLayer.push({ event: 'moreall' });
    e.preventDefault();
    setContent('');
    setActive(true);
    setContent(<AllTariffDetailPopup />);
  };
  const buyAllTariff = () => {
    const ids = [products[0]?.[0]?.id, products[1]?.[1]?.id, products[3]?.[0]?.id].filter(id => id !== undefined).join(',');
    if (ids) {
      window.location.href = `/payment/?product_id=${ids}`;
    }
  };

  const {
    goToFromMenu,
    ScheduleRef,
  } = props;

  return (
    <div className="payment-block" id="payment_block">
      <div ref={innoRef} className="payment-pointBlock"></div>
      <div className="payment-block-h1">
        Сколько стоит билет в вашу новую жизнь?
      </div>
      {isShow && (
        <p className='promo-description'>*Весь май! Скидки до 33% на покупку вашего ПЕРВОГО тарифа в клубе!<br></br>Становитесь КЛУБничками с выгодой!</p>
      )}
      <div className="payment-block-container">
        <div className="payment-block-box">
          <div className="payment-block-box-header payment-block-box-header-green">
            <h1 className="payment-block-box-header-h1">
              тариф<br></br>«БАЗОВЫЙ»
            </h1>
          </div>
          <div className="payment-block-box-content">
            <h2 className="payment-block-box-h2">Еженедельно:</h2>
            <ul className="payment-block-box-p">
              <li>3 эфира омолаживающей гимнастики для лица и тела с Аннетт</li>
              <li>3 эфира йоги</li>
              <li>3 эфира пилатеса</li>
              <li>1 эфир по кулинарии</li>
              <li>1 эфир по психологии</li>
            </ul>
            <span
              onClick={(e) => {
                goToFromMenu(e, ScheduleRef, 'ScheduleRef');
                dataLayer.push({ event: 'schedule' });
              }}
              className="payment-block-box-link"
            >
              Смотреть расписание
            </span>
            <div className="payment-block-price-box">
              <h2 className="payment-block-box-h2">Стоимость</h2>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">{products[1] && products[1][0].period_name}</div>
                <div className="payment-block-box-price">
                  {products[1] && products[1][0].price}
                  {products[1] && products[1][0].economy_discount > 0  && products[1][0].base_price > 0 && (
                    <p className="oldPrice">{products[1][0].base_price} руб.</p>
                  )}
                </div>
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">{products[2] && products[2][1].period_name}</div>
                <div className="payment-block-box-price">
                  {products[2] && products[2][1].price}
                  {products[2] && products[2][1].economy_discount > 0  && products[2][1].base_price > 0 && (
                    <p className="oldPrice">{products[2][1].base_price} руб.</p>
                  )}
                </div>
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">12 месяцев</div>
                <div className="payment-block-box-price">
                  {products[4] && products[4][0].price}
                  {products[4] && products[4][0].economy_discount > 0  && products[4][0].base_price > 0 && (
                    <p className="oldPrice">{products[4][0].base_price} руб.</p>
                  )}
                </div>
              </div>
            </div>
            {isShow && (
              <p className='promo-description-mobile'>*Скидки до 33% для НОВЫХ КЛУБничек!</p>
            )}
            <button
              className="payment-block-more text-7 inter-medium-white-14px"
              onClick={showBasicTariffDetail}
            >
              подробнее
            </button>
            <button
              className="payment-block-join text-7 inter-medium-white-14px"
              onClick={buyBasicTariff}
            >
              Вступить в клуб
            </button>
          </div>
        </div>
        <div className="payment-block-box">
          <div className="payment-block-box-header payment-block-box-header-pink">
            <h1 className="payment-block-box-header-h1">
              тариф<br></br>«ТОЛЬКО АННЕТТ»
            </h1>
          </div>
          <div className="payment-block-box-content">
            <h2 className="payment-block-box-h2">Еженедельно с Аннетт:</h2>
            <ul className="payment-block-box-p">
              <li>
                5 эфиров омолаживающей гимнастики для лица и тела - упражнения,
                массажи
              </li>
              <li>Уникальные «чуфырные» эфиры с ритуалами и медитациями</li>
            </ul>
            <span
              onClick={(e) => {
                goToFromMenu(e, ScheduleRef, 'ScheduleRef');
                dataLayer.push({ event: 'schedule' });
              }}
              className="payment-block-box-link"
            >
              Смотреть расписание
            </span>
            <div className="payment-block-price-box">
              <h2 className="payment-block-box-h2">Стоимость</h2>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">{products[0] && products[0][1].period_name}</div>
                <div className="payment-block-box-price">
                  {products[0] && products[0][1].price}
                  {products[0] && products[0][1].economy_discount > 0  && products[0][1].base_price > 0 && (
                    <p className="oldPrice">{products[0][1].base_price} руб.</p>
                  )}
                </div>
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">{products[2] && products[2][0].period_name}</div>
                <div className="payment-block-box-price">
                  {products[2] && products[2][0].price}
                  {products[2] && products[2][0].economy_discount > 0  && products[2][0].base_price > 0 && (
                    <p className="oldPrice">{products[2][0].base_price} руб.</p>
                  )}
                </div>
              </div>
              {/*<div className="payment-block-box-economy">
                Экономия: {products[2] && products[2][0].economy} руб.
                  </div>*/}
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">12 месяцев</div>
                <div className="payment-block-box-price">
                  {products[3] && products[3][1].price}
                  {products[3] && products[3][1].economy_discount > 0  && products[3][1].base_price > 0 && (
                    <p className="oldPrice">{products[3][1].base_price} руб.</p>
                  )}
                </div>
              </div>
              {/*<div className="payment-block-box-economy">
                Экономия: {products[3] && products[3][1].economy} руб.
                  </div>*/}
            </div>
            {isShow && (
              <p className='promo-description-mobile'>*Скидки до 33% для НОВЫХ КЛУБничек!</p>
            )}
            <button
              className="payment-block-more text-7 inter-medium-white-14px"
              onClick={showAnnettTariffDetail}
            >
              подробнее
            </button>
            <button
              className="payment-block-join text-7 inter-medium-white-14px"
              onClick={buyAnnettTariff}
            >
              Вступить в клуб
            </button>
          </div>
        </div>
        <div className="payment-block-box">
          <div className="payment-block-box-header payment-block-box-header-purple">
            <h1 className="payment-block-box-header-h1">
              тариф<br></br>«ВСЕ ВКЛЮЧЕНО»
            </h1>
          </div>
          <div className="payment-block-box-content">
            <h2 className="payment-block-box-h2">Еженедельно:</h2>
            <ul className="payment-block-box-p">
              <li>
                5 эфиров омолаживающей гимнастики для лица и тела с Аннетт
              </li>
              <li>
                40+ эфиров от экспертов: фитнес, йога, бодифлекс, психология и т.д.
              </li>
            </ul>
            <span
              onClick={(e) => {
                goToFromMenu(e, ScheduleRef, 'ScheduleRef');
                dataLayer.push({ event: 'schedule' });
              }}
              className="payment-block-box-link"
            >
              Смотреть расписание
            </span>
            <div className="payment-block-price-box">
              <h2 className="payment-block-box-h2">Стоимость</h2>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">{products[0] && products[0][0].period_name}</div>
                <div className="payment-block-box-price">
                  {products[0] && products[0][0].price}
                  {products[0] && products[0][0].economy_discount > 0  && products[0][0].base_price > 0 && (
                    <p className="oldPrice">{products[0][0].base_price} руб.</p>
                  )}
                </div>
              </div>
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">{products[1] && products[1][1].period_name}</div>
                <div className="payment-block-box-price">
                  {products[1] && products[1][1].price}
                  {products[1] && products[1][1].economy_discount > 0  && products[1][1].base_price > 0 && (
                    <p className="oldPrice">{products[1][1].base_price} руб.</p>
                  )}
                </div>
              </div>
              {/*<div className="payment-block-box-economy">
                Экономия: {products[1] && products[1][1].economy} руб.
                  </div>*/}
              <div className="payment-block-box-price-container">
                <div className="payment-block-box-period">12 месяцев</div>
                <div className="payment-block-box-price">
                  {products[3] && products[3][0].price}
                  {products[3] && products[3][0].economy_discount > 0  && products[3][0].base_price > 0 && (
                    <p className="oldPrice">{products[3][0].base_price} руб.</p>
                  )}
                </div>
              </div>
              {/*<div className="payment-block-box-economy">
                Экономия: {products[3] && products[3][0].economy} руб.
                  </div>*/}
            </div>
            {isShow && (
              <p className='promo-description-mobile'>*Скидки до 33% для НОВЫХ КЛУБничек!</p>
            )}
            <button
              className="payment-block-more text-7 inter-medium-white-14px"
              onClick={showAllTariffDetail}
            >
              подробнее
            </button>
            <button
              className="payment-block-join text-7 inter-medium-white-14px"
              onClick={buyAllTariff}
            >
              Вступить в клуб
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentBlock;
