import React from "react";
import { getImagesPaths } from "../../shared/utils";
import { SITE_NAME } from "../../shared/constants";
import { MIN_WIDTH_TO_GET_MOBILE_IMAGE } from "../../shared/constants";
import "./SecretOfSuccessBlock.css";

function SecretOfSuccessBlock(props) {
  const {
    successSecretsMainText,
    successSecretsListText,
    successSecretsImages,
    successSecretsImagesOpt,
    innoRef,
  } = props;

  // prepare images
  let contentImages = {};
  if (successSecretsImagesOpt.length > 0) {
    contentImages = getImagesPaths(successSecretsImagesOpt);
  } else {
    contentImages = getImagesPaths(successSecretsImages);
  }

  return (
    <div className="secret-of-success_block">
      <div ref={innoRef} className="secret-of-success-pointBlock"></div>
      <h1 className="text-82 montserrat-extra-bold-stratos-48px">
        {successSecretsMainText}
      </h1>
      <div className="flex-row-2">
        <div className="flex-col">
          <img className="image-5" src={window.innerWidth > MIN_WIDTH_TO_GET_MOBILE_IMAGE ? contentImages['annett'] : contentImages['annett_mobile']} alt="Annett" />
          <div className="frame-12">
            <div className="text-83 montserrat-extra-bold-stratos-18px">
              {successSecretsListText[0]['desc']}
            </div>
            <p className="text_label-2 inter-normal-stratos-14px">
              {successSecretsListText[0]['text']}
            </p>
          </div>
        </div>
        <div className="frame-79">
          <div className="text-98 montserrat-extra-bold-hollywood-cerise-64px">
            «
          </div>
          <div className="x caveat-normal-stratos-32px">
            {successSecretsListText[1]['text']} <br /><br />
            {successSecretsListText[2]['text']} <span className="span1-1">{SITE_NAME} </span>{successSecretsListText[3]['text']}
          </div>
          <div className="text-99 montserrat-extra-bold-hollywood-cerise-64px">
            »
          </div>
        </div>
      </div>

      <div className="frame-27">
        <div className="frame-2-1">
          <div className="frame-15">
            <h2 className="text-88 montserrat-extra-bold-hollywood-cerise-32px">
              {successSecretsListText[4]['desc']}
            </h2>
            <div className="text-89 inter-normal-stratos-18px">
              {successSecretsListText[4]['text']}
            </div>
          </div>
          <img className="rectangle-2-1" src={window.innerWidth > MIN_WIDTH_TO_GET_MOBILE_IMAGE ? contentImages['im1'] : contentImages['im1_mobile']} alt="Rectangle 19" />
        </div>
        <div className="frame-2-2">
          <img className="rectangle-2" src={window.innerWidth > MIN_WIDTH_TO_GET_MOBILE_IMAGE ? contentImages['im2'] : contentImages['im2_mobile']} alt="Rectangle 20" />
          <div className="frame-14">
            <h2 className="text-88 montserrat-extra-bold-hollywood-cerise-32px">
              {successSecretsListText[5]['desc']}
            </h2>
            <div className="text-89 inter-normal-stratos-18px">
              {successSecretsListText[5]['text']}
            </div>
          </div>
        </div>
        {/*<div className="frame-2-1">
          <div className="frame-15">
            <h2 className="text-88 montserrat-extra-bold-hollywood-cerise-32px">
              {successSecretsListText[6]['desc']}
            </h2>
            <div className="text-89 inter-normal-stratos-18px">
              {successSecretsListText[6]['text']}
            </div>
          </div>
          <img className="rectangle-2-1" src={contentImages['im3']} alt="Rectangle 21" />
  </div>*/}
        <div className="frame-2-1">
            <div className="frame-15">
              <h2 className="text-88 montserrat-extra-bold-hollywood-cerise-32px">
                {successSecretsListText[7]['desc']}
              </h2>
              <div className="text-89 inter-normal-stratos-18px">
                {successSecretsListText[7]['text']}
              </div>
            </div>
            <img className="rectangle-2-1" src={window.innerWidth > MIN_WIDTH_TO_GET_MOBILE_IMAGE ? contentImages['im4'] : contentImages['im4_mobile']} alt="Rectangle 21" />
        </div>
        {/*<div className="frame-2-1">
          <div className="frame-15">
            <h2 className="text-88 montserrat-extra-bold-hollywood-cerise-32px">
              {successSecretsListText[8]['desc']}
            </h2>
            <div className="text-89 inter-normal-stratos-18px">
              {successSecretsListText[8]['text']}
            </div>
          </div>
          <img className="rectangle-2-1" src={contentImages['im5']} alt="Rectangle 23" />
        </div>
        <div className="frame-2-2">
          <img className="rectangle-2" src={contentImages['im6']} alt="Rectangle 24" />
          <div className="frame-14">
            <h2 className="text-88 montserrat-extra-bold-hollywood-cerise-32px">
              {successSecretsListText[9]['desc']}
            </h2>
            <div className="text-89 inter-normal-stratos-18px">
              {successSecretsListText[9]['text']}
            </div>
          </div>
  </div>*/}
        <div className="frame-2-2">
          <img className="rectangle-2" src={window.innerWidth > MIN_WIDTH_TO_GET_MOBILE_IMAGE ? contentImages['im7'] : contentImages['im7_mobile']} alt="Rectangle 24" />
          <div className="frame-14">
            <h2 className="text-88 montserrat-extra-bold-hollywood-cerise-32px">
              {successSecretsListText[10]['desc']}
            </h2>
            <div className="text-89 inter-normal-stratos-18px">
              {successSecretsListText[10]['text']}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecretOfSuccessBlock;
