import React from "react";
import "./ChangeLifeBlock.css";
import tv from "../../img/иконки для блока преимущества/tv.svg";
import conversation from "../../img/иконки для блока преимущества/conversation.svg";
import cup from "../../img/иконки для блока преимущества/cup.svg";
import hand from "../../img/иконки для блока преимущества/hand.svg";

function ChangeLifeBlock(props) {

  const {
    innoRef,
  } = props;

  return (
    <div className="change-life_block" id="change-life_block">
      <div ref={innoRef} className="change-life-pointBlock"></div>
      <h1 className="h1-change-life montserrat-extra-bold-hollywood-cerise-80px">
        Меняйте свою жизнь легко и с радостью
      </h1>
      <div className="change-life-container">
        <div className="change-life-box">
          <img className="change-life_block-img" src={tv} alt="image" />
          <div className="frame-17">
            <h2 className="change-life-h2 montserrat-extra-bold-hollywood-cerise-24px">
              3-7 прямых эфиров в день
            </h2>
            <div className="text-75 inter-normal-stratos-18px">
              Тренировки, вебинары, практикумы и мастер-классы. Подключайтесь к
              эфирам или смотрите их в удобное время в записи. Выбирайте
              занятия, которые интересны именно вам, и составляйте
              индивидуальную программу тренировок.
            </div>
          </div>
        </div>
        <div className="change-life-box">
          <img className="change-life_block-img" src={conversation} alt="image" />
          <div className="frame-17">
            <h2 className="change-life-h2 montserrat-extra-bold-hollywood-cerise-24px">
              Живое общение с экспертами
            </h2>
            <div className="text-75 inter-normal-stratos-18px">
              Приходите на прямые эфиры, чтобы задать ведущему интересующие
              вопросы и уточнить, если что-то не понятно. Участвуйте в закрытых
              клубных чатах, где эксперты будут общаться с вами и другими
              участниками.
            </div>
          </div>
        </div>
        <div className="change-life-box">
          <img className="change-life_block-img" src={hand} alt="image" />
          <div className="frame-17">
            <h2 className="change-life-h2 montserrat-extra-bold-hollywood-cerise-24px">
              Сообщество единомышленников
            </h2>
            <div className="text-75 inter-normal-stratos-18px">
              Вы станете частью сообщества людей, которые разделяют ваши
              ценности. Приобретете новых друзей, будете общаться в закрытых
              чатах, встречаться на офлайн и онлайн-встречах, делиться опытом и
              поддерживать друг друга.
            </div>
          </div>
        </div>
        <div className="change-life-box">
          <img className="change-life_block-img" src={cup} alt="image" />
          <div className="frame-17">
            <h2 className="change-life-h2 montserrat-extra-bold-hollywood-cerise-24px">
              Конкурсы
            </h2>
            <div className="text-75 inter-normal-stratos-18px">
              Устраиваем конкурсы, чтобы помочь вам мотивировать себя. Призы —
              до 500 000 рублей. Кто знает, может быть, именно вы выиграете
              денежный приз или другие подарки и полностью окупите свое участие в
              клубе!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeLifeBlock;
