import React from "react";
import { getImagesPaths } from "../../shared/utils";
import "./WhatYouGetBlock.css";
import heart from "../../img/heart.webp";

function WhatYouGetBlock(props) {
  const {
    allForYouMainText,
    allForYouListText,
    whatyouget_Block,
    innoRef,
  } = props;

  return (
    <div
      className="what-you-get_block"
      id="what-you-get_block"
      style={{ backgroundImage: `url(${whatyouget_Block})` }}
    >
      <div ref={innoRef} className="what-you-get-pointBlock"></div>
      <h1 className="beauty-club365 montserrat-extra-bold-white-48px">
        {allForYouMainText}
      </h1>
      <div className="frame-393">
        <div className="frame-389">
          <div className="frame-38">
            <img className="heart" src={heart} alt="heart" />
            <div className="text-10 inter-normal-stratos-18px">
              {allForYouListText[0].text}
            </div>
          </div>
          <div className="frame-38">
            <img className="heart" src={heart} alt="heart" />
            <div className="text-10 inter-normal-stratos-18px">
              {allForYouListText[1].text}
            </div>
          </div>
        </div>
        <div className="frame-389">
          <div className="frame-38">
            <img className="heart" src={heart} alt="heart" />
            <div className="text-10 inter-normal-stratos-18px">
              {allForYouListText[2].text}
            </div>
          </div>
          <div className="frame-38">
            <img className="heart" src={heart} alt="heart" />
            <div className="text-10 inter-normal-stratos-18px">
              {allForYouListText[3].text}
            </div>
          </div>
        </div>
        <div className="frame-389">
          <div className="frame-38">
            <img className="heart" src={heart} alt="heart" />
            <div className="text-10 inter-normal-stratos-18px">
              {allForYouListText[4].text}
            </div>
          </div>
          <div className="frame-38">
            <img className="heart" src={heart} alt="heart" />
            <div className="text-10 inter-normal-stratos-18px">
              {allForYouListText[5].text}
            </div>
          </div>
        </div>
        <div className="frame-389">
          <div className="frame-38">
            <img className="heart" src={heart} alt="heart" />
            <div className="text-10 inter-normal-stratos-18px">
              {allForYouListText[6].text}
            </div>
          </div>
          <div className="frame-38">
            <img className="heart" src={heart} alt="heart" />
            <div className="text-10 inter-normal-stratos-18px">
              {allForYouListText[7].text}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatYouGetBlock;
